import React, { useContext } from 'react';
import { CityContext } from '../Contexts';

function useCity() {
  return useContext(CityContext);
}

function CityConsumer({ children }) {
  return <CityContext.Consumer>{city => children(city)}</CityContext.Consumer>;
}

export {
  useCity,
  // for legacy Components
  CityConsumer,
};
