import React from 'react';

/*
  Convenience function for consuming multiple contexts
  ex:

  <ConsumerList contexts={[CityContext, UserAuthContext]}>
    {(cityContext, authContext) => {
      return <span>Test</span>;
    }}
  </ConsumerList>
*/
const consumerList = ({ contexts, ...props }) =>
  nestRender(contexts, [], props.children);

function nestRender(consumers, contexts = [], children) {
  if (consumers.length === 0) {
    return children(...contexts);
  }

  const item = consumers.shift();

  return (
    <item.Consumer>
      {context => {
        contexts.push(context);

        return nestRender(consumers, contexts, children);
      }}
    </item.Consumer>
  );
}

export default consumerList;
